import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h2 >Địa chỉ không tồn tại.<br/> Xin vui lòng quay lại trang chủ!</h2>
    <Link to={"/"}>Back to site</Link>
  </Layout>
)

export default NotFoundPage
